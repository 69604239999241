<ng-container *transloco="let t; read:'manage-smart-filters'">
  <form [formGroup]="listForm">
    @if (filters.length >= 3) {
      <div class="mb-3">
        <label for="filter" class="form-label">{{t('filter')}}</label>
        <div class="input-group">
          <input id="filter" autocomplete="off" class="form-control" formControlName="filterQuery" type="text" aria-describedby="reset-input">
          <button class="btn btn-outline-secondary" type="button" id="reset-input" (click)="resetFilter()">{{t('clear')}}</button>
        </div>
      </div>
    }
  </form>

  <ul>
    @for(f of filters | filter: filterList; track f.name) {
      <li class="list-group-item">
        <span>
          @if (isErrored(f)) {
            <i class="fa-solid fa-triangle-exclamation red me-2" [ngbTooltip]="t('errored')"></i>
            <span class="visually-hidden">{{t('errored')}}</span>
          }
          <a [href]="'all-series?' + f.filter" target="_blank">{{f.name}}</a>
        </span>
        <button class="btn btn-danger float-end" (click)="deleteFilter(f)">
          <i class="fa-solid fa-trash" aria-hidden="true"></i>
          <span class="visually-hidden">{{t('delete')}}</span>
        </button>
      </li>
    } @empty {
      <li class="list-group-item">
        {{t('no-data')}}
      </li>
    }
  </ul>
</ng-container>
